import clsx from "clsx";
import { Disclosure } from "@headlessui/react";
import {
  Bars3Icon,
  ShoppingCartIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { NavLink, useSearchParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { isProb3 } from "../utils/siteOrigin";
import { CartContext } from "../contexts/CartContext";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";

const navigation = [
  { name: "B3 Bands", path: "/bands", current: true },
  { name: "B3 U Fitness App", path: "/become-a-member", current: false },
  { name: "BFR Courses", path: "/bfr-courses", current: false },
  { name: "Misc Bands", path: "/misc-bands", current: false },
  { name: "B3 Gear", path: "/gear", current: false },
];

export default function Header() {
  const cartContext = useContext(CartContext);
  const [searchParams] = useSearchParams();
  const [name, setName] = useState("");
  const [navs, setNavs] = useState(navigation);

  useEffect(() => {
    if (searchParams.get("sponsorId")) {
      if (
        localStorage.getItem("sponsorId") &&
        localStorage.getItem("sponsorId") !== searchParams.get("sponsorId")
      ) {
        // clear cart and other local storage
        localStorage.removeItem("cart");
        localStorage.removeItem("billingAddress");
        localStorage.removeItem("shippingAddress");
        localStorage.removeItem("cardInfo");
        localStorage.removeItem("memberWebsitePassword");
      }
      localStorage.setItem("sponsorId", searchParams.get("sponsorId"));
    }
  }, [searchParams]);
  useEffect(() => {
    const sponsorId =
      localStorage.getItem("sponsorId") || searchParams.get("sponsorId") || 22;
    fetch(
      `${process.env.REACT_APP_API_URI}/api/Member/sponsorId/${sponsorId}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res) {
          setName(`${res.firstName || ""} ${res.lastName || ""}`);
        }
      });

    // if (isProb3()) {
    //   const newNavs = navigation.filter((n) => n.name === "B3 Bands");
    //   setNavs(newNavs);
    // }
  }, []);

  return (
    <Disclosure
      as="nav"
      className="sticky absolute inset-x-0 top-0 z-50  text-black"
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-9xl  font-['Agrandir TextBold']">
            <div className="bg-white relative flex md:block h-16 items-center md:items-start justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              {/* Desktop Navigation*/}

              <div className="hidden lg:block space-y-4">
                <div className="min-h-screen flex flex-col">
                  {/* Top */}
                  <div className="w-full flex justify-between gap-5 px-4 py-6">
                    <NavLink className="w-fit my-auto" key="logo" to="/">
                      <div className="flex flex-col ">
                        <img
                          alt="logo"
                          src="./assets/images/b3-sciences-logo.png"
                          className="w-[50px] md:w-[120px]"
                        />
                      </div>
                    </NavLink>
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4 lg:static lg:inset-auto  lg:pr-0">
                      <NavLink to="/check-out" className="relative">
                        <button
                          type="button"
                          className="hover:rounded-full hover:bg-[#00ADEF] p-2 hover:text-white"
                        >
                          <span className="sr-only">View Cart</span>
                          <ShoppingCartIcon
                            className="h-6 w-6"
                            aria-hidden="true"
                          />
                        </button>
                        {/* Cart item count  */}
                        <span className="p-2.5 absolute -top-1 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-[#00ADEF] text-white text-xs font-bold">
                          {cartContext.cartCount}
                        </span>
                      </NavLink>
                    </div>
                    {name?.trim() ? (
                      <span className="my-auto">{name}</span>
                    ) : null}
                  </div>
                  {/* Middle */}
                  <div className="!mt-16 px-4 flex-grow">
                    {navs.map((item) => (
                      <NavLink
                        className="w-full block pb-2"
                        key={item.path}
                        to={item.path}
                      >
                        {({ isActive }) => (
                          <Disclosure.Button
                            className={clsx(
                              isActive
                                ? "bg-[#00adef] text-white"
                                : "text-black text-base hover:bg-[#00adef] hover:text-white",
                              "px-3 py-2 text-lg w-full text-left capitalize rounded-3xl flex"
                            )}
                            aria-current={isActive ? "page" : undefined}
                          >
                            {item.name}

                            <div className="inline-block pl-1 relative top-[5px] ml-auto">
                              <ArrowRightIcon
                                fill="white"
                                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                                aria-hidden="true"
                                color="white"
                              />
                            </div>
                          </Disclosure.Button>
                        )}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              {/* Mobile Navigation */}
              <div className="block md:hidden pl-2 lg:pl-6 lg:pl-8">
                <div className="flex items-center justify-center lg:items-stretch lg:justify-start">
                  <div className="hidden lg:block w-full">
                    <div className="flex xl:space-x-4 space-x-1 justify-between">
                      <NavLink key="logo" to="/">
                        <div className="flex flex-col items-center text-center ">
                          <img
                            alt="logo"
                            src="./assets/images/b3-sciences-logo.png"
                            className="w-[50px] md:w-[100px]"
                          />
                        </div>
                      </NavLink>
                      {navs.map((item) => (
                        <NavLink key={item.path} to={item.path}>
                          {({ isActive }) => (
                            <Disclosure.Button
                              className={clsx(
                                isActive
                                  ? "bg-[#00adef] text-white"
                                  : "text-black text-base font-bold hover:bg-[#00adef] hover:text-white",
                                "rounded-md px-3 py-2 text-lg font-bold"
                              )}
                              aria-current={isActive ? "page" : undefined}
                            >
                              {item.name}
                            </Disclosure.Button>
                          )}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <NavLink className="block lg:hidden" key="logo" to="/">
                <div className="flex flex-col items-center text-center ">
                  <img
                    alt="logo"
                    src="./assets/images/b3-sciences-logo.png"
                    className="w-[80px] md:w-[100px]"
                  />
                </div>
              </NavLink>
              <div className="block lg:hidden pr-2 lg:pr-6 lg:pr-8">
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
                  <NavLink to="/check-out" className="relative">
                    <button
                      type="button"
                      className="hover:rounded-full hover:bg-[#00ADEF] p-2 hover:text-white"
                    >
                      <span className="sr-only">View Cart</span>
                      <ShoppingCartIcon
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>
                    {/* Cart item count  */}
                    <span className="p-2.5 absolute -top-1 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-[#00ADEF] text-white text-xs font-bold">
                      {cartContext.cartCount}
                    </span>
                  </NavLink>
                  {name?.trim() ? (
                    <span className="my-auto max-w-[80px] ml-5 py-2">
                      {name}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden bg-white text-black">
            <div className="space-y-1 px-2 pt-2 pb-3 ">
              {navs.map((item) => (
                <NavLink key={item.path} to={item.path}>
                  {({ active }) => (
                    <Disclosure.Button
                      className={clsx(
                        active
                          ? "bg-gray-900 text-black"
                          : "text-black hover:bg-gray-700 hover:text-white",
                        "block rounded-md px-3 py-2 text-base font-medium"
                      )}
                      aria-current={active ? "page" : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  )}
                </NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
