import React, { useState, useContext } from "react";
import { Dialog } from "@headlessui/react";
import Carousel from "../components/UpdatedCarousel";
import PRODUCTS from "../../public/assets/products.json";
import { CartContext } from "../contexts/CartContext";
import { getCart } from "../utils/localstorage";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

const B3Member = () => {
  const [removeSubscription, setRemoveSubscription] = useState(false);
  const [confirmSubscribe, setConfirmSubscribe] = useState(false);
  const cartContext = useContext(CartContext);
  const navigate = useNavigate();

  const memberProduct = {
    ...PRODUCTS.find((p) => p.Sku === "B3SubTD"),
  };

  const handleContinue = () => {
    const cart = getCart();
    const existingMemberSub = cart.find(
      (p) => p?.product?.Sku === memberProduct?.Sku
    );
    if (!existingMemberSub && !removeSubscription) {
      cart.push({ product: memberProduct, qty: 1 });
    }
    if (!existingMemberSub && confirmSubscribe) {
      cart.push({ product: memberProduct, qty: 1 });
    }
    cartContext.save(cart);
    navigate("/check-out");
  };

  return (
    <section className="container mx-auto flex-1 p-0 md:p-5 flex flex-col lg:max-w-full relative">
      <div className="flex flex-col">
        <div className="flex flex-col lg:flex-row gap-2 md:gap-10">
          <div className="flex items-center justify-center mb-4 pt-0 w-full lg:w-1/2 block md:hidden">
            <Carousel swiperImages={["subscription-image.png"]} />
          </div>
          <div className="flex flex-col mb-2 mt-2 md:mt-5 bg-[#302b2b] p-6 rounded-[1rem] w-full">
            <h1 className="pb-4 md:pb-0 text-[2.33rem] md:text-4xl roboto-medium">
              <span className="text-white">
                B<span className="text-[#00adef] mr-2">3</span>U Subscription
              </span>
              <br className="sm:hidden block" />
            </h1>
            <div className="hidden md:block w-full">
              <div className="roboto-regular">
                <p className="py-2 italic text-[#00adef] text-2xl text-white">
                  Your First 30 Days are Free on the B3 U Fitness App
                </p>
                <ul className="list-disc pl-4 pb-2">
                  <li className="py-1 text-xl text-white">
                    Access to Live BFR Workouts
                  </li>
                  <li className="py-1 text-xl text-white">
                    Access to BFR Workout Library
                  </li>
                  <li className="py-1 text-xl text-white">
                    Access to BFR Classes with B
                    <span className="text-[#00adef]">3</span> President Dr Mike
                  </li>
                  <li className="py-1 text-xl text-white">
                    Access to a community of support, inspiration and motivation
                  </li>
                  <li className="py-1 text-xl text-white">
                    Invite 5 friends and your subscription is FREE{" "}
                  </li>
                </ul>
                <p className="text-[#00adef] text-lg text-white">
                  After the first 30 days, your B3 U subscription will renew at
                  $59.99 per month. You can cancel anytime by contacting
                  <a
                    href="mailto:support@b3sciences.com"
                    className="block text-[#00adef]"
                  >
                    support@b3sciences.com
                  </a>
                </p>
                <div className="flex flex-row items-start mt-8 md:mt-4">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={removeSubscription}
                      onChange={() => {
                        setRemoveSubscription(!removeSubscription);
                      }}
                    />
                    <div className="w-11 border !border-[#00adef] h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                  <label
                    className="text-md leading-6 text-white"
                    id="switch-1-label"
                  >
                    No, I do not want the Free 30 days on the{" "}
                    <strong>
                      B<span className="text-[#00adef]">3</span> U Fitness App {" "}
                    </strong>
                    and future monthly subscriptions. I agree to waive these
                    benefits and do not need the coaching, assistance,
                    inspiration and motivation
                  </label>
                </div>
              </div>
            </div>
            <div className="md:hidden mx-auto w-full rounded-2xl shadow">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-white px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-white focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                      <span className="text-lg roboto-bold text-[#00adef]">
                        Details
                      </span>
                      <ChevronUpIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-[#00adef] my-auto`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-white">
                      <div className="block">
                        <div className="roboto-regular">
                          <p className="py-2 italic text-[#00adef] text-lg text-white">
                            Your First 30 Days are Free on the B3 U Fitness App
                          </p>
                          <ul className="list-disc pl-4 pb-2">
                            <li className="py-1 text-md text-white">
                              Access to Live BFR Workouts
                            </li>
                            <li className="py-1 text-md text-white">
                              Access to BFR Workout Library
                            </li>{" "}
                            <li className="py-1 text-md text-white">
                              Access to BFR Classes with B
                              <span className="text-[#00adef]">3</span>{" "}
                              President Dr Mike
                            </li>{" "}
                            <li className="py-1 text-md text-white">
                              Access to a community of support, inspiration and
                              motivation
                            </li>
                          </ul>
                          <p className="text-[#00adef] text-md text-white">
                            After the first 30 days, your B3 U subscription will
                            renew at $59.99 per month. You can cancel anytime by
                            contacting support@b3sciences.com
                          </p>
                          <div className="flex flex-row items-start mt-8 md:mt-4">
                            <label class="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                value=""
                                class="sr-only peer"
                                checked={removeSubscription}
                                onChange={() =>
                                  setRemoveSubscription(!removeSubscription)
                                }
                              />
                              <div class="w-11 border !border-[#00adef] h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            </label>
                            <label
                              class="text-md leading-6 text-white"
                              id="switch-1-label"
                            >
                              No, I do not want the Free 30 days on the{" "}
                              <strong>
                                B<span className="text-[#00adef]">3</span> U
                                Fitness App
                              </strong>{" "}
                              and future monthly subscriptions. I agree to waive
                              these benefits and do not need the coaching,
                              assistance, inspiration and motivation
                            </label>
                          </div>
                        </div>
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
          <div className="flex items-center justify-center mb-4 pt-20 w-full lg:w-1/2 hidden md:block">
            <Carousel
              className="!h-auto"
              swiperImages={["subscription-image.png"]}
            />
          </div>
        </div>
        <div className="text-center pt-2">
          <Button
            onClick={handleContinue}
            className="mx-auto !w-full text-base md:text-lg px-5 py-2 rounded-full font-light mt-2 lg:max-w-[300px]"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
          >
            Continue
          </Button>
        </div>
      </div>

      {/* Dialog for Confirmation */}
      <Dialog
        open={removeSubscription}
        onClose={() => {}}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black bg-opacity-25" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="max-w-lg w-full rounded bg-white p-6">
            <Dialog.Title>
              <h1 className="pb-4 md:pb-0 text-2xl roboto-medium">
                Confirm Subscription
                <br className="sm:hidden block" />
              </h1>
            </Dialog.Title>
            <Dialog.Description className="mt-2">
              <p className="text-[#00adef] text-lg text-black roboto-regular">
                Are you sure you want to cancel your Free 30 days on the{" "}
                <strong>
                  B<span className="text-[#00adef]">3</span> U Fitness App
                </strong>
                ? You won't be able to join us for live workouts and coaching
                online?
              </p>
              <div className="flex flex-row items-start mt-8 md:mt-4">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={confirmSubscribe}
                    autoFocus={false}
                    onChange={() => {
                      setConfirmSubscribe(!confirmSubscribe);

                      //   setRemoveSubscription(!removeSubscription);
                    }}
                  />
                  <div className="w-11 border !border-[#00adef] h-6 mr-4 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
                <label
                  className="text-lg leading-6 roboto-medium text-black"
                  id="switch-1-label"
                >
                  Yes, I want the FREE 30 Days
                  <strong className="ml-1">
                    B<span className="text-[#00adef]">3</span>
                  </strong>{" "}
                  U Subscription
                </label>
              </div>
            </Dialog.Description>
            <div className="text-center pt-2">
              <Button
                onClick={handleContinue}
                className="mx-auto !w-full text-base md:text-lg px-5 py-2 rounded-full font-light mt-2 lg:max-w-[300px]"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                Confirm
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </section>
  );
};

export default B3Member;
