import { Fragment, useState, useContext } from "react";
import Carousel from "../../components/UpdatedCarousel";
import AddToCart from "./AddToCart";
import { Listbox, Transition } from "@headlessui/react";
import { getCart } from "../../utils/localstorage";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Button from "../../components/Button";
import ErrorMessage from "../../components/ErrorMessage";
import PRODUCTS from "../../../public/assets/products.json";
import { CartContext } from "../../contexts/CartContext";
import { toast } from "react-toastify";

const CourseBlock = ({
  title,
  duration,
  component: Component,
  images,
  sku,
  additionalTeamMember,
}) => {
  const additionalTeamSku = "BBCCadd";

  const additionalTeamMemberProduct = PRODUCTS.find(
    (p) => p.Sku === additionalTeamSku
  );
  const additionalPrice = additionalTeamMemberProduct.Price;

  const formatPrice = (price) => {
    const decimalPart = price % 1;
    return (
      Math.floor(price) + (decimalPart >= 0.9 ? 0.99 : decimalPart)
    ).toFixed(2);
  };

  const additionalMemberOptions = Array.from({ length: 10 }, (_, index) => {
    const qty = index + 1;
    return {
      qty,
      price: parseFloat(formatPrice(additionalPrice * qty)),
    };
  });
  const cartContext = useContext(CartContext);

  const [showError, setShowError] = useState(false);

  const [selected, setSelected] = useState(additionalMemberOptions[0]);

  const handleSetChange = (newSet) => {
    setSelected(newSet);
  };

  const handleAddToCart = () => {
    const cart = getCart();
    const existingMainCourse = cart.find((p) => p.product.Sku === "BBCC");
    if (existingMainCourse) {
      const exitAdditionalTeamSku = cart.find(
        (p) => p.product.Sku === additionalTeamSku
      );
      if (exitAdditionalTeamSku) {
        toast("Team is already Added!");
      } else {
        cart.push({ product: additionalTeamMemberProduct, qty: selected.qty });
        toast("Team is  Added!");
      }
    } else {
      setShowError(true);
    }
    cartContext.save(cart);
  };

  return (
    <div className="flex flex-col">
      <div className="grid-cols-1 flex flex-col-reverse lg:grid lg:grid-cols-2 lg:flex-row flex-1 gap-8 md:gap-12">
        <div className="flex flex-col !my-auto mt-2 md:mt-5 h-fit bg-[#302b2b] p-6 rounded-[1rem]">
          <h1 className="pb-4 md:pb-0 text-4xl roboto-medium">
            <span className="mr-2 text-white">{duration}</span>
            <span className="text-white">{title}</span>
          </h1>
          {Component}
          <AddToCart sku={sku} setShowError={setShowError} />
          {additionalTeamMember && (
            <>
              <h1 className="pt-4 pb-2 md:pb-0 md:text-2xl text-lg roboto-medium">
                <span className="text-white">
                  Additional Team Member - Save $100
                </span>
              </h1>
              <div className="md:flex flex-row items-center gap-2 mt-1 md:mt-3">
                <Listbox value={selected} onChange={handleSetChange}>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white text-sm md:text-base px-3 py-3 md:px-6 md:py-4 text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-2xl min-w-[250px] border">
                      <span className="block truncate">
                        {selected.qty} Member
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400 sm:text-2xl"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10">
                        {additionalMemberOptions.map((type, typeIdx) => (
                          <Listbox.Option
                            key={typeIdx}
                            className={() =>
                              `relative cursor-pointer select-none py-2 pl-10 hover:bg-amber-100 pr-4 ${
                                selected.price === type.price
                                  ? "bg-amber-100 text-amber-900"
                                  : "text-gray-900"
                              }`
                            }
                            value={type}
                          >
                            {() => (
                              <div className="flex flex-col text-base font-light">
                                <div>
                                  <span
                                    className={`block roboto-regular truncate ${
                                      selected ? "font-normal" : "font-light"
                                    }`}
                                  >
                                    {type.qty} Member: ${type.price}
                                  </span>
                                  {selected.price == type.price && (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                      <CheckIcon
                                        className="h-4 w-4 md:h-5 md:w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </div>

                                <div className="roboto-regular text-[#00adef] text-xs md:text-sm ">
                                  *SAVE ${type.qty * 100}*
                                </div>
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
              {showError && (
                <ErrorMessage
                  message="Must add the $289.99 course to cart before adding additional team members"
                  onClose={() => setShowError(false)}
                />
              )}
              <div className="md:flex flex-row items-center gap-4 py-2 pl-2 md:pl-0 pt-6 ">
                <div className="flex items-center font-medium mr-4 gap-4">
                  <div className="text-2xl md:text-4xl text-[#00adef] my-auto">
                    ${selected.price}
                  </div>
                  <Button
                    onClick={handleAddToCart}
                    className="text-lg md:text-4xl italic px-5 py-2 rounded-full font-normal md:mt-0 roboto-regular-italic"
                    data-mdb-ripple="true"
                    data-mdb-ripple-color="light"
                  >
                    ADD TO CART
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex items-center">
          <Carousel
            className="!h-auto !m-0"
            swiperImages={images}
            allowExpand
          />
        </div>
      </div>
    </div>
  );
};

export default CourseBlock;
