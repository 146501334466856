import { NavLink } from "react-router-dom";
import Button from "../../components/Button";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import Course from "./Course";
import CourseBlock from "./CourseBlock";

const sixHourImages = ["founder2.png"];
const twoHourRefresherImages = ["refresher-main.png"];
const twoHourRehab = ["rehab-main.png"];
const twoHourExercise = ["exercise-main.png"];
const twoHourPerformance = ["performance-main.png"];

const courseData = [
  {
    title: "Hour BFR Course",
    duration: 6,
    component: (
      <Course
        courseDetails={[
          { text: "A comprehensive course on BFR" },
          { text: "History, Science, and Application" },
          {
            text: "Certification Exam included",
          },
          {
            text: (
              <>
                Approved for various
                <NavLink
                  key="for more info"
                  to="https://b3sciences.com/bfr-certified-coach/"
                  target="_blank"
                >
                  <span className="ml-1 hover:border-b text-[#00adef] hover:border-[#00adef]">
                    continuing education credits
                  </span>
                </NavLink>
              </>
            ),
          },
        ]}
      />
    ),
    sku: "BBCC",
    images: sixHourImages,
    additionalTeamMember: true,
  },
  {
    title: "Hour – Yearly Refresher BFR Course",
    duration: 2,
    component: (
      <Course
        courseDetails={[
          { text: "A yearly refresher course with new content" },
          { text: "Continues B3 Certified Coach certification for 1 year" },
        ]}
      />
    ),
    sku: "BBCCre",
    images: twoHourRefresherImages,
  },
  {
    title: "Hour - Rehab Protocols",
    duration: 2,
    component: (
      <Course
        courseDetails={[{ text: "Practical applications of BFR in Rehab" }]}
      />
    ),
    sku: "BBCCAdv1",
    images: twoHourRehab,
  },
  {
    title: "Hour - Exercise Protocols",
    duration: 2,
    component: (
      <Course
        courseDetails={[{ text: "Practical applications of BFR in Exercise" }]}
      />
    ),
    sku: "BBCCAdv2",
    images: twoHourExercise,
  },
  {
    title: "Hour - Performance Protocols",
    duration: 2,
    component: (
      <Course
        courseDetails={[
          { text: "Practical applications of BFR in Sport and Performance" },
        ]}
      />
    ),
    sku: "BBCCAdv3",
    images: twoHourPerformance,
  },
];

export const BFRCourses = () => {
  return (
    <section className="container mx-auto flex-1 p-5 flex flex-col lg:max-w-full relative">
      <h1 className="pb-4 pl-1 md:py-4 md:text-5xl text-5xl roboto-medium">
        <span>BFR Courses</span>
      </h1>
      <div className="flex flex-col gap-16 pl-1">
        {courseData.map((course, index) => (
          <>
            <CourseBlock
              key={index}
              title={course.title}
              duration={course.duration}
              component={course.component}
              images={course.images}
              sku={course.sku}
              additionalTeamMember={course?.additionalTeamMember}
            />
          </>
        ))}
      </div>
      <div className="w-full flex justify-between mt-10 mb-7 flex-row items-center">
        <NavLink key="opportunity" to="/become-a-member">
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            <div className="inline-block pr-1 relative top-[3px]">
              <ArrowLeftIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
            {"Back"}
          </Button>
        </NavLink>
        <NavLink key="misc-bands" to="/misc-bands">
          <Button className="uppercase text-xs md:text-base px-4 py-1 rounded-full font-light w-auto">
            Next
            <div className="inline-block pl-1 relative top-[3px]">
              <ArrowRightIcon
                fill="white"
                className="h-[0.9rem] md:h-[1.1rem] w-[1.1rem]"
                aria-hidden="true"
                color="white"
              />
            </div>
          </Button>
        </NavLink>
      </div>
    </section>
  );
};
