export default function WhatsIncluded() {
  return (
    <div className="roboto-regular">
      <ul className="list-disc pl-2 py-3">
        <li className="py-1 text-xl">B3 Bands Sets (arms, legs, pump)</li>
        <li className="py-1 text-xl">B3 Duffel Bag & Exercise Tubing</li>
        <li className="py-1 text-xl">B3 U Fitness App – Free First 30 Days</li>
        <li className="py-1 text-xl">Live Weekly Coaching Calls</li>
        <li className="py-1 text-xl">30 Day Money Back Guarantee</li>
        <li className="py-1 text-xl">12 Month Warranty</li>
      </ul>
    </div>
  );
}
