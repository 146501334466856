import { useState, useContext } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import { getCart } from "../../utils/localstorage";
import PRODUCTS from "../../../public/assets/products.json";
import { CartContext } from "../../contexts/CartContext";

export default function AddToCart({ sku, setOpen }) {
  const notify = () => toast("Product is added!");
  const cartContext = useContext(CartContext);
  const [qty] = useState(1);
  const product = PRODUCTS.find((p) => p.Sku === sku);

  const handleAddToCart = (e) => {
    if (qty > 0) {
      notify();
      setOpen(true);
      const cart = getCart();
      const existing = cart.find((p) => p.product.Sku === sku);
      if (existing) {
        existing.qty = (Number(existing.qty) || 0) + qty;
      } else {
        cart.push({ product, qty });
      }
      cartContext.save(cart);
    }
  };

  return (
    <div className="flex sm:flex-row flex-col px-2">
      <div className="flex custom-wrap-center items-center font-medium mr-4 gap-4 md:gap-10">
        <div className="!text-2xl md:!text-4xl text-[#00adef] my-auto">
          ${product.Price}
        </div>
        <Button
          onClick={handleAddToCart}
          className="text-lg md:text-4xl italic px-5 py-2 rounded-full font-normal md:mt-0 roboto-regular-italic"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
        >
          ADD TO CART
        </Button>
      </div>
    </div>
  );
}
