import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import { useLocation } from "react-router-dom";
import ExternalLinks from "../pages/checkout/ExternalLinks";
import clsx from "clsx";

export default function Layout() {
	const location = useLocation();
	const isHome = location.pathname === "/";
  
	const routeSettings = {
	  "/check-out": { showFooter: false, showExternalLinks: false },
	  "/shipping": { showFooter: false, showExternalLinks: false },
	  "/payment": { showFooter: false, showExternalLinks: false },
	  "/confirmation": { showFooter: false, showExternalLinks: true },
	  default: { showFooter: true, showExternalLinks: true },
	};
  
	const { showFooter, showExternalLinks } =
	  routeSettings[location.pathname] || routeSettings.default;
	return (
		<>
		<ScrollToTop />
		<div className="flex flex-col min-h-screen">
		  {/* Main Content */}
		  <div className="flex flex-col lg:flex-row flex-grow">
			{/* Header */}
			<div className="w-full lg:w-1/6 border-r-[1px] border-r-[#ebeaea]">
			  <Header />
			</div>
  
			{/* Main Content */}
			<main
			  className={clsx(
				"flex-grow mx-auto w-full lg:w-5/6",
				isHome ? "max-w-10xl" : "max-w-[82rem]"
			  )}
			>
			  <Outlet />
			  <ToastContainer />
			</main>
		  </div>
  
		  {/* Footer */}
		  <footer
			className={clsx(
			  showFooter || showExternalLinks
				? "block items-center justify-center w-full bg-white border-t border-t-[#ebeaea] py-6"
				: "hidden"
			)}
		  >
			{showFooter && <Footer />}
			{showExternalLinks && <ExternalLinks />}
		  </footer>
		</div>
	  </>
	)
}
